/**
 * Data object representing the search patients list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class SearchPatientsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} firstName - Patients first name.
	 * @param {string} middleName - Patients middle name.
	 * @param {string} lastName - Patients last name.
	 * @param {string} address - Address of the patient.
	 * @param {string} telephone - Patients landline number.
	 * @param {string} mobilePhone - Patients mobile phone number.
	 * @param {string} email - Patients email address.
	 * @param {Date} birthDate - Patients date of birth.
	 * @param {string} recommendationId - ID of the recommendation.
	 * @param {string} recommendationName - Recommendation text value.
	 */
	constructor(id, firstName,	middleName,	lastName, address, telephone,	mobilePhone, email, birthDate, recommendationId,
	recommendationName)
{
		this.id = id;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.address = address;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.birthDate = birthDate;
		this.recommendationId = recommendationId;
		this.recommendationName = recommendationName;
	}
}

