import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, isset} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject,
	DataTableCellNumberTypeOptionsDataObject,
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import {getAppLocaleDateFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME as LOCALE_DATE_FORMAT} from "Core/const/locale";
import {Link} from "react-router-dom";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {forOwn, get} from "lodash";
import {capitalize} from "Core/helpers/string";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "react-tippy";
import {routerPath as patientRecordRouterPath} from "Pages/apps/default/patientRecord/config";
import {AsyncMountError} from "Core/errors";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainListData: selectors[reducerStoreKey].getSearchTherapiesListData(state),
	mainList: selectors[reducerStoreKey].getSearchTherapiesList(state),
	mainListPagination: selectors[reducerStoreKey].getSearchTherapiesListPagination(state),
	mainListSort: selectors[reducerStoreKey].getSearchTherapiesListSort(state),
	mainListFilter: selectors[reducerStoreKey].getSearchTherapiesListFilter(state),
});

class SearchTherapiesPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},
			/**
			 * Medical record category codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			medicalRecordCategoryCodebook: undefined,
			/**
			 * Therapy type codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapyTypeCodebook: undefined,
			/**
			 * Therapy subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubtypeCodebook: undefined,
			/**
			 * Therapy sub-subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubSubtypeCodebook: undefined
		}, {
			domPrefix: 'search-therapies-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
			renderTitle: false,
			
			// Custom component options
			/**
			 * Date used as an initial/default date from in the main filter
			 * @note Also used when filter is cleared.
			 * @type {Date}
			 */
			initialDateFrom: null,
			/**
			 * Date used as an initial/default date to in the main filter
			 * @note Also used when filter is cleared.
			 * @type {Date}
			 */
			initialDateTo: null,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.loadPageCodebook = this.loadPageCodebook.bind(this);
		this.clearTherapySubtypes = this.clearTherapySubtypes.bind(this);
		this.clearTherapySubSubtypes = this.clearTherapySubSubtypes.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		
		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);

		// Reload main list when new appointment has been created using the header button because it is possible to create
		// a new therapy while creating a new appointment
		this.registerEventListener('onHeaderAppointmentCreated', () => {
			if (this.getProp('mainListData') !== null) this.reloadMainList().then();
		});
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainListData, mainListFilter} = this.props;

		try {
			// If main list data was already loaded (user already searched for something)
			if (mainListData !== null) {
				// Reload main list with currently applied filter, sort and pagination
				this.reloadMainList().then(res => { if (!isset(res)) throw new AsyncMountError(); });
			}

			// Load all codebooks used in the applied filter
			// @description Filter select components that use codebooks load them on menu open, so they need to be preloaded 
			// for all applied filter fields in order to select them properly (usually when opening the page).
			const codebooks = {
				medicalRecordCategory: 'selectedMedicalRecordCategoryId',
				therapyType: 'selectedTherapyTypeId',
				therapySubtype: 'selectedTherapySubTypeId',
				therapySubSubtype: 'selectedTherapySubSubTypeId'
			};

			forOwn(codebooks, (filterField, codebook) => {
				if (!!get(mainListFilter, filterField) && !isset(get(this.state, `${codebook}Codebook`))) {
					let actionParams = [];
					switch (codebook) {
						case 'therapySubtype': actionParams = [get(mainListFilter, 'selectedTherapyTypeId', null)]; break;
						case 'therapySubSubtype': actionParams = [get(mainListFilter, 'selectedTherapySubTypeId', null)]; break;
						// no default
					}
					this.loadPageCodebook(codebook, ...actionParams).then();
				}
			});
		} catch (e) {
			console.error(e);
			throw new AsyncMountError();
		}
	}

	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadPageCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Clear therapy subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubtypes() {
		return this.mainListFilterRef.clearField('therapySubtype')
			.then(() => this.setState({therapySubtypeCodebook: undefined}));
	}

	/**
	 * Clear therapy sub-subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubSubtypes() {
		return this.mainListFilterRef.clearField('therapySubSubtype')
			.then(() => this.setState({therapySubSubtypeCodebook: undefined}));
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadSearchTherapiesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchTherapiesListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadSearchTherapiesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchTherapiesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadSearchTherapiesListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchTherapiesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadSearchTherapiesListAction, setSearchTherapiesFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setSearchTherapiesFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadSearchTherapiesListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		const {resetSearchTherapiesListAction} = this.props;
		return this.executeAbortableAction(resetSearchTherapiesListAction)
			.then(() => this.mainListFilterRef.clearField('dateFrom', this.getOption('initialDateFrom')))
			.then(() => this.mainListFilterRef.clearField('dateTo', this.getOption('initialDateTo')));
	}

	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		// Open main list item popup if it is not already opened
		// @note This is done to avoid opening multiple dialogs when switching from item edit to new item URL. Dynamic URL
		// component data should be changes using the standard 'updateDynamics' method.
		if (!this.urlComponentGUIID) {
			return this.openMainListItemPopup();
		}
		// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			return mainListItemPopup.updateDynamics({isNew: true}).then(() => this.urlComponentGUIID);
		}
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {loadPrescribedTherapiesItemAction} = this.props;	

		// @note This is done to avoid opening multiple dialogs when switching from new item to edit item URL. Dynamic URL
		// component data should be changes using the standard 'updateDynamics' method.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openMainListItemPopup();
		}
		// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			mainListItemPopup.updateDynamics().then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadPrescribedTherapiesItemAction, id)
					.then(mainListItem => {
						// If main list item is successfully loaded
						if (mainListItem) {
							return this.urlComponentGUIID;
						}
						// If main list item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close main list item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes main list item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 */
	openMainListItemPopup() {
		const {openPopupAction} = this.props;
		return openPopupAction(ItemPopup, {
			onClose: this.handleMainListItemPopupClose,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {closePopupAction, clearPrescribedTherapiesItemAction} = this.props;

		// Close item popup
		const urlComponentGUIID = this.getUrlComponentGUIID();
		if (urlComponentGUIID) {
			closePopupAction(urlComponentGUIID);
			this.clearUrlComponentGUIID();
		}

		// Clear popup Redux data
		clearPrescribedTherapiesItemAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleMainListItemPopupClose() {
		this.clearUrlComponentGUIID();
		this.redirectToBase();
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadMainList()))
		);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {SearchTherapiesListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('therapy_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="object-ungroup"
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}
	
	render() {
		const {
			mainListData, mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank,
			toggleMainSidebarSizeAction
		} = this.props;
		const {
			medicalRecordCategoryCodebook, therapyTypeCodebook, therapySubtypeCodebook, therapySubSubtypeCodebook
		} = this.state;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-7"
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('dateFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(mainListFilter, 'dateFrom', this.getOption('initialDateFrom')),
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('dateToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(mainListFilter, 'dateTo', this.getOption('initialDateTo')),
							}
						),
						new SimpleStaticSearchOptionObject(
							'patientFirstName',
							this.t('patientFirstNameField')
						),
						new SimpleStaticSearchOptionObject(
							'patientLastName',
							this.t('patientLastNameField')
						),
						new SimpleStaticSearchOptionObject(
							'patientAddress',
							this.t('patientAddressField')
						),
						new SimpleStaticSearchOptionObject(
							'selectedMedicalRecordCategoryId',
							this.t('medicalRecordCategoryField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(medicalRecordCategoryCodebook).map(i => ({ label: i.name, value: i.id })),
								onMenuOpen: () => {
									if (!isset(medicalRecordCategoryCodebook)) this.loadPageCodebook('medicalRecordCategory')
										.then();
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'medicalRecordNumber',
							this.t('medicalRecordNumberField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.NUMBER,
							{
								intOnly: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'selectedTherapyTypeId',
							this.t('selectedTherapyTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapyTypeCodebook).map(i => ({label: i.name, value: i.id})),
								onMenuOpen: () => {
									if (!isset(therapyTypeCodebook)) this.loadPageCodebook('therapyType').then();
								},
								onChange: v => {
									this.mainListFilterRef.handleValueChange('selectedTherapyTypeId', v);
									this.clearTherapySubtypes()
										.then(() => this.clearTherapySubSubtypes())
										.then(() => {if (v) this.loadPageCodebook('therapySubtype', v).then(); });
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'selectedTherapySubTypeId',
							this.t('selectedTherapySubTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapySubtypeCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapySubtypeCodebook === null),
								onChange: v => {
									this.mainListFilterRef.handleValueChange('selectedTherapySubTypeId', v);
									this.clearTherapySubSubtypes()
										.then(() => {if (v) this.loadPageCodebook('therapySubSubtype', v).then(); })
								},
								isDisabled: !isset(therapySubtypeCodebook),
							}
						),
						new SimpleStaticSearchOptionObject(
							'selectedTherapySubSubTypeId',
							this.t('selectedTherapySubSubTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapySubSubtypeCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapySubSubtypeCodebook === null),
								isDisabled: !isset(therapySubSubtypeCodebook),
							}
						),
						new SimpleStaticSearchOptionObject(
							'prescribedTherapiesCount',
							this.t('prescribedTherapiesCountField')
						),
						new SimpleStaticSearchOptionObject(
							'diagnosis',
							this.t('diagnosisField')
						),
						new SimpleStaticSearchOptionObject(
							'sentByPhysician',
							this.t('sentByPhysicianField')
						)
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('page_title')} />)}
					applied={mainListData !== null}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				{mainListData !== null ?
					<DataTable
						id="main-page-table"
						responsive={true}
						responsiveBreakpointName="bp-l"
						className="standard sticky-last-column"
						columns={[
							{
								name: 'therapyDate',
								label: this.t('therapyDateField'),
								dataType: DATA_TABLE_CELL_TYPE.DATE,
								dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
									outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT.SHORT),
									whiteSpace: 'nowrap'
								}),
								defaultValue: '—',
								emptyAsDefault: true,
								width: 1,
							},
							{
								label: this.t('customerFullNameField'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									content: listItem => (
										<Link to={`${patientRecordRouterPath}/item/${listItem.customerId}`}>
											<Label content={listItem.customerFullName} />
										</Link>
									)
								}),
								minWidth: 150,
								stopPropagation: true
							},
							{
								name: 'diagnosis',
								label: this.t('diagnosisField'),
								minWidth: 150,
								defaultValue: '—',
								emptyAsDefault: true,
							},
							{
								name: 'sentByPhysician',
								label: this.t('sentByPhysicianField'),
								minWidth: 150,
								widthLessThanLabel: true,
								defaultValue: '—',
								emptyAsDefault: true,
							},
							{
								name: 'numberOfTherapies',
								label: this.t('numberOfTherapiesField'),
								dataType: DATA_TABLE_CELL_TYPE.NUMBER,
								dataTypeOptions: new DataTableCellNumberTypeOptionsDataObject({
									alignContent: 'center',
								}),
								width: 1,
								widthLessThanLabel: true,
							},
							{
								name: 'medicalRecordCategory',
								label: this.t('medicalRecordCategoryField'),
								minWidth: 150,
								defaultValue: '—',
								emptyAsDefault: true,
							},
							{
								name: 'medicalRecordNumber',
								label: this.t('medicalRecordNumberField'),
								dataType: DATA_TABLE_CELL_TYPE.NUMBER,
								dataTypeOptions: new DataTableCellNumberTypeOptionsDataObject({
									alignContent: 'center',
								}),
								width: 1
							},
							{
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									standardWrapper: false,
									content: this.renderActions,
								}),
								stopPropagation: true,
								width: 1,
							}
						]}
						data={mainList}
						paginationType={PAGINATION_TYPE.DYNAMIC}
						onSortByColumn={this.sortMainList}
						onPaginationClick={this.loadMainListPage}
						{...mainListPagination}
						{...mainListSort}
					/>
					: this.getValue('filterLoading') ?
						<div className="page-notice-wrapper no-select">
							<div className="page-notice-title-icon icon">
								<Spinner size={60} weight={3} />
							</div>
						</div>
					:
						<div className="page-notice-wrapper no-select">
							<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
							<Label
								element="p"
								elementProps={{className: 'page-notice-title bold'}}
								content={this.t('search_notice_title')}
							/>
							<Label
								element="p"
								elementProps={{className: 'page-notice'}}
								content={this.t('search_notice_description')}
							/>
						</div>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(SearchTherapiesPage);