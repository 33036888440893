/**
 * Abstract popup component used to create popups
 */

import React from "react";
import PropTypes from "prop-types";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import {v4} from "uuid";
import {
	get,
	set,
	cloneDeep,
	sortBy,
	orderBy,
	filter,
	find,
	minBy,
	reduce,
	isFunction,
	isEmpty,
	findIndex, 
	omit
} from "lodash";
import {icon_font_loading_symbol, icon_font_symbol_class_prefix, responsive_mobile_breakpoint_name} from "Config/app";
import {GUI_STATUS} from "Core/const/global";
import {getString, isset} from "Core/helpers/data";
import Button from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import {LABEL_ICON_POSITION} from "Core/components/display/Label";
import {isCurrentBreakpointSmallerOrEqual} from "Core/helpers/dom";

// Constants
export const POPUP_ACTION_BUTTON_LOCATION_TAB = 'tab';
export const POPUP_ACTION_BUTTON_LOCATION_GLOBAL = 'global';
export const POPUP_ACTION_BUTTON_LOCATION = {
	TAB: POPUP_ACTION_BUTTON_LOCATION_TAB,
	GLOBAL: POPUP_ACTION_BUTTON_LOCATION_GLOBAL
}
export const POPUP_ACTION_BUTTON_LOCATIONS = [
	POPUP_ACTION_BUTTON_LOCATION_TAB, POPUP_ACTION_BUTTON_LOCATION_GLOBAL
];

class PopupComponent extends BaseComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 * 
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname;
	
	/**
	 * Object containing all popup tab content component refs
	 * @type {{string, Node}}
	 */
	tabRefs = {};

	/**
	 * Dynamically loaded tab components
	 * @description Dynamically loaded popup tab content inner components must be loaded and stored in this value, 
	 * outside local, state in order for the to work properly. Storing and rendering them from local state will unmount 
	 * and remount them on each local state change which causes a lot of problems.
	 * @type {Object<string, React.Component|JSX.Element|null>}
	 */
	tabComponents = {};
	
	/**
	 * Popup component constructor
	 * 
	 * @param {object} props - Component props.
	 * @param {PopupComponentOptions} [options={}] - Component options from child class that will override the default
	 * options.
	 */
	constructor(props, options = {}) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {PopupComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Timeout in ms (milliseconds) for DOM manipulation interval. If less than zero DOM manipulation interval will
			 * be disabled.
			 * @type {number}
			 */
			domManipulationIntervalTimeout: 10,
			
			/**
			 * Flag that determines if tabs section will be hidden if there is only one visible tab
			 * @type {boolean}
			 */
			hideSingleTab: false,

			...cloneDeep(options)
		}

		super(props, _options);

		// Initialize initial state
		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Popup methods
		this.init = this.init.bind(this);
		this.importTabComponents = this.importTabComponents.bind(this);
		this.handleTabComponentImport = this.handleTabComponentImport.bind(this);
		this.close = this.close.bind(this);

		// Tab methods
		this.getCurrentTab = this.getCurrentTab.bind(this);
		this.getCurrentTabIndex = this.getCurrentTabIndex.bind(this);
		this.setCurrentTab = this.setCurrentTab.bind(this);
		this.goToPreviousTab = this.goToPreviousTab.bind(this);
		this.goToNextTab = this.goToNextTab.bind(this);
		this.getTabs = this.getTabs.bind(this);
		this.getSortedTabs = this.getSortedTabs.bind(this);
		this.getVisibleTabs = this.getVisibleTabs.bind(this);
		this.tabExists = this.tabExists.bind(this);
		this.getTab = this.getTab.bind(this);
		this.getTabIndex = this.getTabIndex.bind(this);
		this.addTab = this.addTab.bind(this);
		this.updateTab = this.updateTab.bind(this);
		this.removeTab = this.removeTab.bind(this);
		this.showTab = this.showTab.bind(this);
		this.hideTab = this.hideTab.bind(this);
		this.enableTab = this.enableTab.bind(this);
		this.disableTab = this.disableTab.bind(this);
		this.setTabs = this.setTabs.bind(this);
		this.addTabs = this.addTabs.bind(this);
		this.removeTabs = this.removeTabs.bind(this);
		this.showTabs = this.showTabs.bind(this);
		this.hideTabs = this.hideTabs.bind(this);
		this.enableTabs = this.enableTabs.bind(this);
		this.disableTabs = this.disableTabs.bind(this);
		this.getTabRef = this.getTabRef.bind(this);
		this.setTabData = this.setTabData.bind(this);
		this.getTabData = this.getTabData.bind(this);
		this.handleTabClick = this.handleTabClick.bind(this);
		this.handleTabLoading = this.handleTabLoading.bind(this);
		this.handleTabLoaded = this.handleTabLoaded.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleTabValidate = this.handleTabValidate.bind(this);
		this.handleGlobalActionButtonClick = this.handleGlobalActionButtonClick.bind(this);
		this.handleTabActionButtonClick = this.handleTabActionButtonClick.bind(this);

		// Action methods
		this.getActions = this.getActions.bind(this);
		this.getSortedActions = this.getSortedActions.bind(this);
		this.getActionsGlobal = this.getActionsGlobal.bind(this);
		this.getVisibleActionsGlobal = this.getVisibleActionsGlobal.bind(this);
		this.getActionsTab = this.getActionsTab.bind(this);
		this.getVisibleActionsTab = this.getVisibleActionsTab.bind(this);
		this.actionExists = this.actionExists.bind(this);
		this.getAction = this.getAction.bind(this);
		this.addAction = this.addAction.bind(this);
		this.removeAction = this.removeAction.bind(this);
		this.updateAction = this.updateAction.bind(this);
		this.showAction = this.showAction.bind(this);
		this.hideAction = this.hideAction.bind(this);
		this.enableAction = this.enableAction.bind(this);
		this.disableAction = this.disableAction.bind(this);
		this.setActions = this.setActions.bind(this);
		this.addActions = this.addActions.bind(this);
		this.removeActions = this.removeActions.bind(this);
		this.showActions = this.showActions.bind(this);
		this.hideActions = this.hideActions.bind(this);
		this.enableActions = this.enableActions.bind(this);
		this.disableActions = this.disableActions.bind(this);
		
		// Dynamic methods
		this.dynamicActionButtons = this.dynamicActionButtons.bind(this);
		this.dynamicTabs = this.dynamicTabs.bind(this);
		this.updateDynamics = this.updateDynamics.bind(this);

		// Render methods
		this.renderTabs = this.renderTabs.bind(this);
		this.renderTabContent = this.renderTabContent.bind(this);
		this.renderGlobalActionLegend = this.renderGlobalActionLegend.bind(this);
		this.renderGlobalActionButton = this.renderGlobalActionButton.bind(this);
		this.renderGlobalActionButtons = this.renderGlobalActionButtons.bind(this);
		this.renderTabActionButton = this.renderTabActionButton.bind(this);
		this.renderTabActionButtons = this.renderTabActionButtons.bind(this);
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|void>} Promise that will resolve with the updated mount count that will be set in the 
	 * 'componentDidMount' method or undefined for default functionality where 'componentDidMount' will just reset the 
	 * mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();
		
		// Initialize popup (tabs, actions, ...)
		await this.init();
		
		// Try to get current tab id from state
		let currentTabId = this.state.currentTabId;
		// If current tab ID is not set, try to find the first tab that has a component loaded into 'tabComponents' 
		// @note Search is done by tab ordinal.
		if (!currentTabId) {
			/** @type PopupTabDataObject */
			const tabWithMinOrdinal = minBy(
				filter(this.getVisibleTabs(), tab => !!get(this.tabComponents, tab.id)), 'ordinal'
			);
			if (tabWithMinOrdinal) currentTabId = tabWithMinOrdinal.id;
			else console.log(`%cCould not find any popup tab to load in '${this.dirname}'.`, 'color: orange');
		}

		// Set current tab id
		await this.setCurrentTab(currentTabId);

		return Promise.resolve();
	}

	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return `popup-component-${this.props.popupGUIID}`; }

	/**
	 * Method called on each DOM manipulation interval if component has a defined DOM element (see 'getDomElement'
	 * method).
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element is 
	 * not set.
	 */
	domManipulations(element) {
		if (element) {
			// Handle tab content DOM manipulations
			const tabContentElements = element.querySelectorAll('.popup-tab-content');
			tabContentElements.forEach(tabContentElement => {
				// Handle popup tab action buttons related DOM manipulations
				const popupTabActionButtons = tabContentElement.querySelector('.popup-tab-action-buttons');
				if (popupTabActionButtons) {
					const popupTitleElem = tabContentElement.querySelector('.popup-title');

					// Manage 'no-title' CSS class for popup tab action buttons based on title availability
					if (!popupTitleElem) popupTabActionButtons.classList.add('no-title');
					else popupTabActionButtons.classList.remove('no-title');

					// Add right padding to tab content title if popup action buttons are present
					if (popupTitleElem) {
						const popupTabActionButtonsWidth = reduce(
							popupTabActionButtons.children,
							(accumulator, currentValue) => {
								return accumulator += currentValue.getBoundingClientRect().width;
							},
							15
						);
						popupTitleElem.style.paddingRight = `${popupTabActionButtonsWidth}px`;
					}
				}
			});
		}
	}

	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has its
	 * component loaded into 'tabComponents'.
	 * 
	 * @note This is just a placeholder method, and it should be defined in child class if it is required. See the code 
	 * reference to get an idea of how to implement the actual method.
	 * 
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() { 
		return Promise.resolve(this.state);
		
		// Code reference for actual method implementation
		/*
		await this.setActions([
			new PopupActionDataObject(...),
			new PopupActionDataObject(...)
		]);

		await this.setTabs([
			new PopupTabDataObject(...),
			new PopupTabDataObject(...)
		]).then(this.importTabComponents);
		
		return Promise.resolve(this.state);
		 */
	}
	
	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 * 
	 * @note This is an abstract method and should not be called directly! It should be defined in child classes using
	 * the code of this method as a reference. It must be defined inside the actual popup component in order for dynamic
	 * import to work properly.
	 * @note This method must be defined inside the actual popup component in order for dynamic import to work properly.
	 * 
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		throw new Error('Not implemented! This is an abstract class method.');
		
		// Code reference for actual method implementation
		/*
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			const loading = this.showLoading();
			if (!isset(tab.component)) {
				return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`))
					.then(tabComponent => { this.hideLoading(loading); return tabComponent; });
			} else {
				return Promise.resolve(this.state)
					.then(state => { hideLoading(loading); return state; });
			}
		}));
		*/
	}
	
	/**
	 * Handle promise from dynamically loaded tab component
	 * 
	 * @param {PopupTabDataObject} tab - Tab of the dynamically loaded component.
	 * @param {Promise} importPromise - Dynamically loaded tab component import promise.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabComponentImport(tab, importPromise) {
		return importPromise.then(({default: TabComponent}) => {
			set(this.tabComponents, tab.id, TabComponent);
			return this.state;
		}).catch(() => {
			console.log(`%cPopup tab '${tab.id}' component does not exist.`, 'color: orange');
		});
	}
	
	/**
	 * Popup close method
	 */
	close() {
		const {popupCloseAction} = this.props;
		popupCloseAction();
	}

	/**
	 * Get currently visible tab
	 * @return {PopupTabDataObject|undefined} Currently visible tab data object or undefined.
	 */
	getCurrentTab() { return find(this.getVisibleTabs(), {id: this.state.currentTabId}); }

	/**
	 * Get the index of the currently visible tab in the tabs list
	 * @return {number}
	 */
	getCurrentTabIndex() { return findIndex(this.getVisibleTabs(), {id: this.state.currentTabId}); }

	/**
	 * Set current tab
	 * 
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setCurrentTab(id) { return this.setState({currentTabId: id}); }

	/**
	 * Set the current tab to be the tab previous from the current tab
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	goToPreviousTab() {
		const currentTabIndex = this.getCurrentTabIndex();
		const tabs = this.getTabs();
		if (currentTabIndex > 0) {
			const prevTab = get(tabs, currentTabIndex - 1);
			if (prevTab) return this.setCurrentTab(prevTab.id);
			else console.error('Trying to move to the previous tab but the tab does not exist!');
		} else {
			console.log('%cTrying to move to the previous tab while on first tab', 'color: orange');
		}
		return Promise.resolve(this.state);
	}

	/**
	 * Set the current tab to be the tab next to the current tab
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	goToNextTab() {
		const currentTabIndex = this.getCurrentTabIndex();
		const tabs = this.getTabs();
		if (currentTabIndex < (tabs.length - 1)) {
			const nextTab = get(tabs, currentTabIndex + 1);
			if (nextTab) return this.setCurrentTab(nextTab.id);
			else console.error('Trying to move to the next tab but the tab does not exist!');
		} else {
			console.warn('%cTrying to move to the next tab while on last tab', 'color: orange');
		}
		return Promise.resolve(this.state);
	}

	/**
	 * Get all tabs
	 * @return {PopupTabDataObject[]}
	 */
	getTabs() { return this.state.tabs; }
	
	/**
	 * Get properly sorted list of tabs
	 * @return {PopupTabDataObject[]}
	 */
	getSortedTabs() { return sortBy(this.state.tabs, ['ordinal']); }
	
	/**
	 * Get the properly sorted list of visible tabs
	 * @return {PopupTabDataObject[]} 
	 */
	getVisibleTabs() { return filter(this.getSortedTabs(), {visible: true}); }

	/**
	 * Check if tab exists in the list of tabs
	 * @note This will check the whole list.
	 *
	 * @param {string} id - Tab ID.
	 * @return {boolean} True if tab exists in the list of tabs, false otherwise.
	 */
	tabExists(id) { return !!find(this.getTabs(), {id}); }

	/**
	 * Get tab by ID
	 * 
	 * @param {string} id - Tab ID.
	 * @return {PopupTabDataObject|null} - Tab or null if tab does not exist.
	 */
	getTab(id) {
		const tab = find(this.getTabs(), {id});
		return (tab ? tab : null);
	}

	/**
	 * Get the index fo the tab, specified by ID, in the tabs list
	 *
	 * @param {string} id - Tab ID.
	 * @return {number}
	 */
	getTabIndex(id) { return findIndex(this.getTabs(), {id}); }
	
	/**
	 * Add new tab and properly sort the list of tabs
	 * 
	 * @param {PopupTabDataObject} tab - Tab to add to the list of tabs.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	addTab(tab) {
		if (this.tabExists(tab.id)) {
			console.log(
				`%cCould not add popup tab because tab with the same ID '${tab.id}' already exists!`, 'color: red'
			);
			return Promise.resolve(this.state);
		} else {
			set(this.tabComponents, tab.id, tab.component);
			return this.addStateArrayItem('tabs', tab);
		}
	}

	/**
	 * Update existing tab
	 * @note Tab ID or component should not be changed once the tab is created and this method uses that assumption.
	 *
	 * @param {PopupTabDataObject} tab - Tab to update with all tab data.
	 * @return {Promise<any>}
	 */
	updateTab(tab) {
		if (this.tabExists(tab.id)) {
			return this.updateStateArrayItem('tabs', {id: tab.id}, tab);
		} else {
			console.log(
				`%cCould not update popup tab because tab with the ID '${tab.id}' does not exist!`, 'color: red'
			);
			return Promise.resolve(this.state);
		}
	}

	/**
	 * Remove tab from the tab list
	 * 
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated. 
	 */
	removeTab(id) {
		delete this.tabComponents[id];
		return this.removeStateArrayItem('tabs', {id}); 
	}

	/**
	 * Show tab
	 *
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	showTab(id) { return this.setStateArrayItemValue('tabs', {id}, 'visible', true); }

	/**
	 * Hide tab
	 *
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	hideTab(id) { return this.setStateArrayItemValue('tabs', {id}, 'visible', false); }

	/**
	 * Enable tab
	 *
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	enableTab(id) { return this.setStateArrayItemValue('tabs', {id}, 'disabled', false); }

	/**
	 * Disable tab
	 *
	 * @param {string} id - Tab ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	disableTab(id) { return this.setStateArrayItemValue('tabs', {id}, 'disabled', true); }

	/**
	 * Set tabs
	 * @note This will clear the tab list before adding new tabs to it.
	 * 
	 * @param {PopupTabDataObject[]} [tabs=[]] - List of tabs to add. If not specified or empty array, tab list will just
	 * be cleared.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addTab' methods for each added tab.
	 */
	setTabs(tabs = []) {
		this.tabComponents = {};
		return this.setState({tabs: []}).then(() => this.addTabs(tabs)); 
	}
	
	/**
	 * Add multiple tab and properly sort the list of tabs
	 * 
	 * @param {PopupTabDataObject[]} tabs - List of tabs to add.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addTab' methods for each added tab.
	 */
	addTabs(tabs) { return Promise.all(tabs.map(tab => this.addTab(tab))); }

	/**
	 * Remove multiple tabs from the list of tabs
	 *
	 * @param {string[]} ids - List of tab IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'removeTab' methods for each removed tab.
	 */
	removeTabs(ids) { return Promise.all(ids.map(id => this.removeTab(id))); }

	/**
	 * Show multiple tabs
	 * 
	 * @param {string[]} ids - List of tab IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'showTab' methods for each showed tab.
	 */
	showTabs(ids) { return Promise.all(ids.map(id => this.showTab(id))); }

	/**
	 * Hide multiple tabs
	 *
	 * @param {string[]} ids - List of tab IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'hideTab' methods for each hidden tab.
	 */
	hideTabs(ids) { return Promise.all(ids.map(id => this.hideTab(id))); }

	/**
	 * Enable multiple tabs
	 *
	 * @param {string[]} ids - List of tab IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'enableTab' methods for each enabled tab.
	 */
	enableTabs(ids) { return Promise.all(ids.map(id => this.enableTab(id))); }

	/**
	 * Disable multiple tabs
	 *
	 * @param {string[]} ids - List of tab IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'disableTab' methods for each disabled 
	 * tab.
	 */
	disableTabs(ids) { return Promise.all(ids.map(id => this.disableTab(id))); }

	/**
	 * Get tab's ref
	 *
	 * @param {string} id - Popup tab ID.
	 * @return {Object}
	 */
	getTabRef(id) { return get(this.tabRefs, id); }
	
	/**
	 * Set tab's internal data
	 * 
	 * @param {string} id - Popup tab ID.
	 * @param {any} data - Tab data to set.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setTabData(id, data) { return this.setStateArrayItemValue('tabs', {id}, 'data', data); }
	
	/**
	 * Get tab's internal data
	 * @note Each tab that exports any data should have a 'getTabData' that returns the export data. This method uses tab
	 * component refs to get tab components and call their 'getTabData' method.
	 * 
	 * @param {string} id - Popup tab ID.
	 * @return {any|undefined} Tab data or undefined if tab does not exist, or it does not have 'getTabData' method 
	 * returning the tab data.
	 */
	getTabData(id) {
		const tabRef = get(this.tabRefs, id);
		return (tabRef && isFunction(get(tabRef, 'getTabData')) ? tabRef.getTabData() : undefined);
	}

	/**
	 * Get internal data of all tabs
	 * @note Each tab that exports any data should have a 'getTabData' that returns the export data. This method uses tab
	 * component refs to get tab components and call their 'getTabData' method. 
	 * @return {Object} Internal tab data object where keys are tab IDs and values are internal tabs data.
	 */
	getAllTabsData() {
		let result = {};
		if (this.tabRefs) {
			Object.keys(this.tabRefs).forEach(tabRefKey => set(result, tabRefKey, this.getTabData(tabRefKey)));
		}
		return (isEmpty(result) ? null : result);
	}
	
	/**
	 * Handle tab element click
	 * 
	 * @param {string} tabId - Clicked popup tab ID.
	 * @param {MouseEvent} event - Mouse click event for clicked popup tab DOM element.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabClick(tabId, event) {
		// Set current tab
		return this.setCurrentTab(tabId)
			// Call 'handleTabClick' method of the popup that was clicked
			.then(() => {
				const tabRef = get(this.tabRefs, tabId);
				if (tabRef && isFunction(get(tabRef, 'handleTabClick'))) tabRef.handleTabClick(tabId, event);
			});
	}

	/**
	 * Handle tab loading event
	 * 
	 * @param {string} id - Popup tab ID of the tab that is loading.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabLoading(id) { return this.setStateArrayItemValue('tabs', {id}, 'loading', true); }

	/**
	 * Handle tab loaded event
	 * 
	 * @param {string} id - Popup tab ID of the tab has been loaded.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabLoaded(id) { return this.setStateArrayItemValue('tabs', {id}, 'loading', false); }

	/**
	 * Handle tab change event
	 *
	 * @param {string} id - Popup tab ID of the tab has been changed.
	 * @param {any} data - Popup tab data after change.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabChange(id, data) {
		// Call 'handleOtherTabChange' method in all popup tabs except the tag that triggered this change event
		if (this.tabRefs) {
			Object.keys(this.tabRefs).forEach(tabRefKey => {
				if (id !== tabRefKey) {
					const tebRef = this.tabRefs[tabRefKey];
					if (isFunction(get(tebRef, 'handleOtherTabChange'))) tebRef.handleOtherTabChange(id, data);
				}
			});
		}
	}

	/**
	 * Handle tab validate event
	 * 
	 * @param {string} id - Popup tab ID of the tab has been changed.
	 * @param {boolean} valid - True if popup tab data is valid, false otherwise.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	handleTabValidate(id, valid) { return this.setStateArrayItemValue('tabs', {id}, 'valid', valid); }

	/**
	 * Handle global action button click
	 * @note This method will trigger 'onGlobalAction' event with following arguments: action id, internal data of all 
	 * tabs after action function was executed, tab id of the tab that was active when action button was clicked, 
	 * action function result or resolved data if action function returns a promise.
	 *
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {PopupActionDataObject} action - Action's 'action' method will be called with following arguments: internal
	 * data of all tabs, action button's click event, action 'id'.
	 */
	handleGlobalActionButtonClick(event, action) {
		const allTabsData = this.getAllTabsData();
		const currentTabId = this.state.currentTabId;
		const actionResult = action.action(allTabsData, event, action.id);
		
		// If action function result is a promise
		if (actionResult instanceof Promise) {
			actionResult.then((...resolveData) => {
				executeComponentCallback(this.props.onGlobalAction, 
					action.id, this.getAllTabsData(), currentTabId, ...resolveData
				);
			})
		}
		// If action function result is not a promise
		else {
			executeComponentCallback(this.props.onGlobalAction,
				action.id, this.getAllTabsData(), currentTabId, actionResult
			);
		}
	}

	/**
	 * Handle tab action button click
	 * @note This method will trigger 'onTabAction' event with following arguments: action id, internal data of the tab
	 * that called the action after action function was executed, tab id of the tab that called the action, action 
	 * function result or resolved data if action function returns a promise.
	 *
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {PopupActionDataObject} action - Action's 'action' method will be called with following arguments: internal
	 * data of current tab, action button's click event, action 'id'.
	 */
	handleTabActionButtonClick(event, action) {
		const currentTabId = this.state.currentTabId;
		const tabData = this.getTabData(currentTabId);
		const actionResult = action.action(tabData, event, action.id);

		// If action function result is a promise
		if (actionResult instanceof Promise) {
			actionResult.then((...resolveData) => {
				executeComponentCallback(this.props.onTabAction,
					action.id, this.getTabData(currentTabId), currentTabId, ...resolveData
				);
			})
		}
		// If action function result is not a promise
		else {
			executeComponentCallback(this.props.onTabAction,
				action.id, this.getTabData(currentTabId), currentTabId, actionResult
			);
		}
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get all actions
	 * @return {PopupActionDataObject[]}
	 */
	getActions() { return this.state.actions; }
	
	/**
	 * Get properly sorted list of actions
	 * @return {PopupActionDataObject[]}
	 */
	getSortedActions() { return sortBy(this.state.actions, ['ordinal']); }

	/**
	 * Get the properly sorted list of global popup actions
	 * @return {PopupActionDataObject[]}
	 */
	getActionsGlobal() {
		return filter(this.getSortedActions(), a => (
			a.location === POPUP_ACTION_BUTTON_LOCATION.GLOBAL && (
				a.tabs.includes('*') || a.tabs.includes(this.state.currentTabId)
			)
		));
	}
	
	/**
	 * Get the properly sorted list of visible global popup actions
	 * @return {PopupActionDataObject[]}
	 */
	getVisibleActionsGlobal() {
		return filter(this.getSortedActions(), a => (
			a.visible === true && a.location === POPUP_ACTION_BUTTON_LOCATION.GLOBAL && (
				a.tabs.includes('*') || a.tabs.includes(this.state.currentTabId)
			)
		));
	}

	/**
	 * Get the properly sorted list of tab popup actions for the current tab
	 * @return {PopupActionDataObject[]}
	 */
	getActionsTab() {
		return filter(this.getSortedActions(), a => (
			a.location === POPUP_ACTION_BUTTON_LOCATION.TAB && (
				a.tabs.includes('*') || a.tabs.includes(this.state.currentTabId)
			)
		));
	}
	
	/**
	 * Get the properly sorted list of visible tab popup actions for the current tab
	 * @return {PopupActionDataObject[]}
	 */
	getVisibleActionsTab() {
		return filter(this.getSortedActions(), a => (
			a.visible === true && a.location === POPUP_ACTION_BUTTON_LOCATION.TAB && (
				a.tabs.includes('*') || a.tabs.includes(this.state.currentTabId)
			)
		));
	}

	/**
	 * Check if action exists in the list of actions
	 * @note This will check the whole list.
	 *
	 * @param {string} id - Action ID.
	 * @return {boolean} True if action exists in the list of actions, false otherwise.
	 */
	actionExists(id) { return !!find(this.getActions(), {id}); }

	/**
	 * Get action by ID
	 *
	 * @param {string} id - Action ID.
	 * @return {PopupActionDataObject|null} - Action or null if action does not exist.
	 */
	getAction(id) {
		const action = find(this.getActions(), {id});
		return (action ? action : null);
	}
	
	/**
	 * Add new action
	 * 
	 * @param {PopupActionDataObject} action - Action to add to the list of actions.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	addAction(action) {
		if (this.actionExists(action.id)) {
			console.log(
				`%cCould not add popup action because acton with the same ID '${action.id}' already exists!`, 'color: red'
			);
			return Promise.resolve(this.state);
		} else {
			return this.addStateArrayItem('actions', action);
		}
	}

	/**
	 * Remove action from the action list
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	removeAction(id) { return this.removeStateArrayItem('actions', {id}); }

	/**
	 * Update an existing action
	 * 
	 * @param {string} id - Action ID.
	 * @param {Object} updater - Action updater.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	updateAction(id, updater) {
		if (this.actionExists(id)) return this.updateStateArrayItem('actions', {id}, updater);
		return Promise.resolve(this.state);
	}

	/**
	 * Show action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	showAction(id) { return this.updateStateArrayItem('actions', {id}, {visible: true}); }

	/**
	 * Hide action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	hideAction(id) { return this.updateStateArrayItem('actions', {id}, {visible: false}); }

	/**
	 * Enable action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	enableAction(id) { return this.updateStateArrayItem('actions', {id}, {disabled: false}); }

	/**
	 * Disable action
	 *
	 * @param {string} id - Action ID.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	disableAction(id) { return this.updateStateArrayItem('actions', {id}, {disabled: true}); }

	/**
	 * Set actions
	 * @note This will clear the action list before adding new actions to it.
	 * 
	 * @param {PopupActionDataObject[]} [actions=[]] - List of actions to add. If not specified or empty array, action 
	 * list will just be cleared.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addAction' methods for each added 
	 * action.
	 */
	setActions(actions = []) { 
		return this.setState({actions: []}).then(() => this.addActions(actions)); 
	}

	/**
	 * Add multiple actions
	 *
	 * @param {PopupActionDataObject[]} actions - List of actions to add.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'addAction' methods for each added 
	 * action.
	 */
	addActions(actions) { return Promise.all(actions.map(action => this.addAction(action))); }

	/**
	 * Remove multiple actions from the list of actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'removeAction' methods for each removed 
	 * action.
	 */
	removeActions(ids) { return Promise.all(ids.map(id => this.removeAction(id))); }

	/**
	 * Show multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'showAction' methods for each showed 
	 * action.
	 */
	showActions(ids) { return Promise.all(ids.map(id => this.showAction(id))); }

	/**
	 * Hide multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'hideAction' methods for each hidden 
	 * action.
	 */
	hideActions(ids) { return Promise.all(ids.map(id => this.hideAction(id))); }

	/**
	 * Enable multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'enableAction' methods for each enabled 
	 * action.
	 */
	enableActions(ids) { return Promise.all(ids.map(id => this.enableAction(id))); }

	/**
	 * Disable multiple actions
	 *
	 * @param {string[]} ids - List of action IDs.
	 * @return {Promise<any[]>} Promise that resolves with an array of resolved 'disableAction' methods for each disabled
	 * action.
	 */
	disableActions(ids) { return Promise.all(ids.map(id => this.disableAction(id))); }
	
	
	// Dynamic methods --------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {Object} [dynamicValues={}] - Dynamic current state and props values action buttons depend on.
	 * @return {Promise<unknown>}
	 */
	async dynamicActionButtons(dynamicValues = {}) {
		// Implement in child component usually by removing the existing dynamic actions and calculating if they should be
		// added again
		
		// For example:
		//		await this.removeAction(...);
		//		await this.addAction(new PopupActionDataObject(...));
		
		return Promise.resolve();
	}

	/**
	 * Update dynamic tabs that depend on current state and props
	 * @param {Object} [dynamicValues={}] - Dynamic current state and props values tabs depend on.
	 * @return {Promise<unknown>}
	 */
	dynamicTabs(dynamicValues = {}) {
		return Promise.allSettled(
			this.getTabs().map(tab => this.updateTab({
				...tab, 
				componentProps: {...tab.componentProps, ...dynamicValues}
			}))
		);
	}

	/**
	 * Update dynamic component items (tabs, action buttons, ...) that depend on state or prop changes
	 * @param {Object} [dynamicValues={}] - Dynamic current state and props values action buttons and tabs depend on.
	 * @return {Promise<unknown>}
	 */
	updateDynamics(dynamicValues = {}) {
		// Update dynamic tabs that depend on the new status
		return this.dynamicTabs(dynamicValues)
			// Update dynamic action buttons that depend on the new status
			.then(() => this.dynamicActionButtons(dynamicValues));
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render tabs menu
	 * @return {null|JSX.Element}
	 */
	renderTabs() {
		const tabs = this.getVisibleTabs();
		
		// Do not render tabs if component is configured not to render tabs if there is ony one visible tab
		if (tabs.length === 1 && this.getOption('hideSingleTab') === true) return null;
		
		// Set parent popup CSS classes
		const componentElement = this.getDomElement();
		if (componentElement) {
			const popupElement = componentElement.closest('.popup');
			if (tabs.length) popupElement.classList.add('width-tabs');
			else popupElement.classList.remove('width-tabs');
		}
		
		return (
			tabs.length > 0 ?
				<div className="popup-tabs no-select">
					{tabs.map(tab =>
						<div 
							key={tab.id}
							className={
								`popup-tab status-${tab.GUISTATUS}` +
								`${tab.disabled ? ' disabled ' : ''} ${tab.valid ? '' : ' invalid '}` +
								(tab.id === this.state.currentTabId ? ' active ' : '') 
							}
							onClick={(!tab.disabled ? e => this.handleTabClick(tab.id, e) : null)}
						>
							<Label
								icon={
									isCurrentBreakpointSmallerOrEqual(tab.smallBreakpoint) ?
										(
											tab.loading ?
												icon_font_loading_symbol :
												(
													typeof tab.iconSmall === 'string' ?
														tab.iconSmall :
														getString(tab.iconSmall, 'symbol')
												)
										)
										:
										(
											tab.loading ?
												icon_font_loading_symbol :
												(typeof tab.icon === 'string' ? tab.icon : getString(tab.icon, 'symbol'))
										)
								}
								iconSymbolPrefix={
									isCurrentBreakpointSmallerOrEqual(tab.smallBreakpoint) ?
										(
											typeof tab.iconSmall === 'string' ?
												icon_font_symbol_class_prefix :
												getString(tab.iconSmall, 'symbolPrefix', icon_font_symbol_class_prefix)
										)
										:
										(
											typeof tab.icon === 'string' ?
												icon_font_symbol_class_prefix :
												getString(tab.icon, 'symbolPrefix', icon_font_symbol_class_prefix)
										)
								}
								iconSpin={tab.loading}
								content={
									isCurrentBreakpointSmallerOrEqual(tab.smallBreakpoint) ?
										(tab.labelSmall ? this.translatePath(tab.labelSmall) : '') :
										(tab.label ? this.translatePath(tab.label) : '')
								}
								tooltip={tab.tooltip ? this.translatePath(tab.tooltip) : ''}
								iconPosition={LABEL_ICON_POSITION.LEFT}
								suffix={tab.GUISTATUS === GUI_STATUS.CHANGED ? '*' : ''}
							/>
						</div>
					)}
				</div>
			: null
		);
	}
	
	/**
	 * Render tab content
	 * 
	 * @param {PopupTabDataObject} tab - Popup tab to render.
	 * @return {JSX.Element}
	 */
	renderTabContent(tab) {
		const currentTab = this.getCurrentTab();
		const tabActionButtons = this.renderTabActionButtons();
		
		return (
			isset(currentTab) ?
				<div 
					key={tab.id}
					className={`popup-tab-content ${tabActionButtons ? ' with-action-buttons' : ''}`}
					style={{display: (currentTab?.id === tab.id ? 'block' : 'none')}}
				>
					{tabActionButtons}
					{
						!!get(this.tabComponents, tab.id) ?
							React.createElement(this.tabComponents[tab.id], {
								...tab.componentProps,
								key: tab.id,
								id: tab.id,
								data: tab.data,
								popup: this,
								tab: omit(tab, ['data', 'component', 'componentProps']),
								onLoading: this.handleTabLoading,
								onLoaded: this.handleTabLoaded,
								onChange: this.handleTabChange,
								onValidate: this.handleTabValidate,
								ref: node => { set(this.tabRefs, tab.id, node); }
							})
							: null
					}
				</div>
				:
				<div className={`popup-tab-content ${tabActionButtons ? ' with-action-buttons' : ''}`} />
		);
	}

	/**
	 * Render content for all tabs that should be preloaded
	 * @return {JSX.Element}
	 */
	renderAllTabContent() {
		const preloadTabs = filter(this.getSortedTabs(), t => (t.preload || t.id === this.state.currentTabId));
		const tabs = orderBy(preloadTabs, ['preloadPriority'], ['desc']);
		const hasTabActionButtons = this.getVisibleActionsTab().length;
		
		return (
			tabs.length ?
				<>
					{tabs.map(tab =>
						<React.Fragment key={tab.id}>
							{this.renderTabContent(tab)}
						</React.Fragment>
					)}
				</>
				:
				<div className={`popup-tab-content ${hasTabActionButtons ? ' with-action-buttons' : ''}`} />
		);
	}

	/**
	 * Render global action legend
	 * @return {JSX.Element}
	 */
	renderGlobalActionLegend() {
		return null;
		
		// For example: 
		/*return (
			<div className="legend">
				<sup><strong>1</strong></sup>&nbsp;<Label content={this.t('legend_1')} />
			</div>
		);*/
	}

	/**
	 * Render a single global action button
	 * @note Global actions are actions that apply to the whole popup.
	 *
	 * @param {PopupActionDataObject} action - Action data.
	 * @return {JSX.Element|null}
	 */
	renderGlobalActionButton(action) {
		const ActionButton = (!!action.customButtonComponent ? action.customButtonComponent : Button);
		const actionButtonProps = (
			!!action.customButtonComponent ? action.customButtonComponentProps : action.buttonProps
		);
		
		return (
			<ActionButton
				key={action.GUIID}
				{...actionButtonProps}
				className={`action-${action.id} ${getString(actionButtonProps, 'className')}`}
				label={
					get(actionButtonProps, 'label') ?
						this.translatePath(get(actionButtonProps, 'label')) :
						''
				}
				disabled={action.disabled}
				onClick={e => this.handleGlobalActionButtonClick(e, action)}
			/>
		);
	}
	
	/**
	 * Render global action buttons
	 * @note Global actions are actions that apply to the whole popup.
	 * 
	 * @return {JSX.Element|null}
	 */
	renderGlobalActionButtons() {
		const actions = this.getVisibleActionsGlobal();
		
		return (
			actions.length > 0 ?
				<div className="popup-global-action-buttons">
					{this.renderGlobalActionLegend()}

					<div className="action-buttons">
						{actions.map(this.renderGlobalActionButton)}
					</div>
				</div>
			: null
		);
	}

	/**
	 * Render a single tab action button
	 * @note Tab actions are actions that apply to a single tab. Tab action buttons are rendered separately from global
	 * action buttons.
	 * 
	 * @param {PopupActionDataObject} action - Action data.
	 * @return {JSX.Element}
	 */
	renderTabActionButton(action) {
		const ActionButton = (!!action.customButtonComponent ? action.customButtonComponent : Button);
		const actionButtonProps = (
			!!action.customButtonComponent ? action.customButtonComponentProps : action.buttonProps
		);
		
		return (
			<ActionButton
				key={action.GUIID}
				{...actionButtonProps}
				label={
					get(actionButtonProps, 'label') ?
						this.translatePath(get(actionButtonProps, 'label')) :
						''
				}
				disabled={action.disabled}
				onClick={e => this.handleTabActionButtonClick(e, action)}
			/>
		);
	}
	
	/**
	 * Render tab action buttons
	 * @note Tab actions are actions that apply to a single tab. Tab action buttons are rendered separately from global 
	 * action buttons.
	 * 
	 * @return {JSX.Element|null}
	 */
	renderTabActionButtons() {
		const actions = this.getVisibleActionsTab();

		return (
			actions.length > 0 ?
				<div className="popup-tab-action-buttons">
					{actions.map(this.renderTabActionButton)}
				</div>
				: null
		);
	}
	
	render() {
		const {styleName} = this.props;
		
		return (
			<div id={this.getDomId()} className={`popup-component popup-content ${styleName ? `style-${styleName}` : ''}`}>
				{this.renderTabs()}
				{this.renderAllTabContent()}
				{this.renderGlobalActionButtons()}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PopupComponent.propTypes = {
	// Props received from global Popup component -----------------------------------------------------------------------
	// Unique GUI ID of the popup
	popupGUIID: PropTypes.string,
	// Popup options
	popupOptions: PropTypes.object,
	// Action used to close the popup
	// @description This is the action that actually closes the popup. Using an internal component's 'close' method this 
	// action will be called to close the popup. Just before closing the popup, this action will call the 'onClose' event 
	// if it was defined in props (see 'onClose' prop below for more details).
	popupCloseAction: PropTypes.func,

	// Popup specific props ---------------------------------------------------------------------------------------------
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	
	// Events triggered by the component --------------------------------------------------------------------------------
	// Close event
	// @trigger This event is triggered by the global Popup component's 'close' method just before the popup is closed. 
	// @description If this event returns boolean false or a Promise that resolves to boolean false, popup will not be 
	// close ('closePopupAction' will not be called). Otherwise, if it returns anything else or does not return anything 
	// at all, popup will be closed by calling the 'closePopupAction'.
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PopupComponent.defaultProps = {
	styleName: '',
};


// Data objects
/**
 * Popup tab data object
 * @note Data object are objects use only for defining data formats and storing data.
 */
export class PopupTabDataObject {
	/**
	 * @param {string} id - Unique ID of the popup tab. This is a required param.
	 * @param {string|{symbol: string, [symbolPrefix]: string}} [icon=''] - Popup tab icon symbol name.
	 * @param {string|{symbol: string, [symbolPrefix]: string}} [iconSmall] - Popup tab icon symbol name used on 
	 * resolutions smaller than the breakpoint defined in 'smallBreakpoint'. If undefined, 'icon' will be used.
	 * @param {string} [label=''] - Label translation path. Path is used to enable rendering properly translated label 
	 * when locale changes.
	 * @param {string} [labelSmall] - Small label translation path used on resolutions smaller than the breakpoint 
	 * defined in 'smallBreakpoint'. If undefined, 'label' will be used. Path is used to enable rendering properly 
	 * translated label when locale changes.
	 * @param {string} [tooltip=''] - Popup tab tooltip translation path. Path is used to enable rendering properly 
	 * translated label when locale changes.
	 * @param {string} [smallBreakpoint] - Breakpoint name (from app config) used to detect the small screen resolution. 
	 * Default value will be retrieved from app config 'responsive_mobile_breakpoint_name' option.
	 * @param {number} [ordinal=0] - Popup tab order position.
	 * @param {boolean} [visible=true] - Flag that determines if popup tab should be visible.
	 * @param {boolean} [disabled=false] - Flag that determines if popup tab should be disabled.
	 * @param {boolean} [preload=true] - Flag that determines if popup tab should be preloaded.
	 * @param {number} [preloadPriority=0] - Popup tabs will be preloaded in the order of priority from high to low.
	 * @param {any} [data] - Popup tab data.
	 * @param {React.Component|JSX.Element|null} [component] - Component used to render the tab content. If not defined,
	 * component will be automatically loaded from standard location. Standard location is a 'tabs' subdirectory relative
	 * to the location of the popup component containing the tab and where tab component should be inside a file or
	 * subdirectory with index file where name must be the same as 'id'. For example, if PopupTabDataObject has 'id' of
	 * 'InfoTab', tab component should be either in 'tabs/InfoTab.js' or 'tabs/InfoTab/index.js'.
	 * @param {Object} [componentProps={}] - Props that will be sent to the component in addition predefined props:
	 * 	- id, data, popup, tab, onLoading, onLoaded, onChange, onValidate
	 * These props will be ignored because they are used internally for core component functionality. 
	 * @param {boolean} [valid=true] - Flag that specifies if popup tab data is valid.
	 * @param {boolean} [loading=false] - Flag that specifies if popup tab is loading.
	 * @param {string} [GUISTATUS='pristine'] - GUI status of the popup tab (see GUI_STATUS).
	 * @param {string} [GUIID] - Unique GUI ID of the popup tab.
	 */
	constructor({
		id, icon = '', iconSmall, label = '', labelSmall, tooltip = '', smallBreakpoint, ordinal = 0, visible = true, 
		disabled = false, preload = true, preloadPriority = 0, data = undefined, component = undefined, 
		componentProps = {}, valid = true, loading = false, GUISTATUS = GUI_STATUS.PRISTINE, GUIID = v4()
	}) {
		// Check for missing params
		if (!isset(id) || !id) throw new Error(`${this.constructor.name} error! Required ID param is missing or empty.`);
		
		this.id = id;
		this.icon = icon;
		this.iconSmall = (isset(iconSmall) ? iconSmall : icon);
		this.label = label;
		this.labelSmall = (isset(labelSmall) ? labelSmall : label);
		this.tooltip = tooltip;
		this.smallBreakpoint = (
			isset(smallBreakpoint) && smallBreakpoint ? 
				smallBreakpoint : 
				responsive_mobile_breakpoint_name
		);
		this.visible = visible;
		this.disabled = disabled;
		this.ordinal = ordinal;
		this.preload = preload;
		this.preloadPriority = preloadPriority;
		this.loading = loading;
		this.data = data;
		this.valid = valid;
		this.component = component;
		this.componentProps = componentProps;
		this.GUISTATUS = GUISTATUS;
		this.GUIID = GUIID;
	}
}

/**
 * Popup action data object
 * @note Data object are objects use only for defining data formats and storing data.
 */
export class PopupActionDataObject {
	/**
	 *
	 * @param {string} id - Unique ID of the popup action. This is a required param.
	 * @param {Function} action - Action function to execute on action button click. This is a required param.
	 * @param {{
	 *    [id]: string, [className]: string, [type]: string, [displayType]: string, [displayStyle]: string, 
	 *    [big]: boolean, [name]: string, [value]: string, [autofocus]: boolean, [disabled]: boolean, [hide]: boolean,
	 *    [label]: string, [icon]: string, [spinIcon]: boolean, [onClick]: Function
	 * }} buttonProps - Action Button component props. This is a required param. Please note that 'label' button prop 
	 * should be a translation path of the label instead of the translated value label. This is done to enable rendering 
	 * properly translated label when locale changes.
	 * @param {string} [location='global'] - Define where to render action button for this action (see
	 * POPUP_ACTION_BUTTON_LOCATIONS).
	 * @param {Array} [tabs=['*']] - List of IDs of tabs on which this action should be available.
	 * @param {number} [ordinal=0] - Order position of the action button.
	 * @param {boolean} [visible=true] - Flag that determines if action button for this action will be visible.
	 * @param {boolean} [disabled=false] - Flag that determines if action button for this action will be disabled.
	 * @param {?Component} [customButtonComponent=null] - Custom component used to render the action button.
	 * @param {Object} [customButtonComponentProps={}] - Props used by the custom component.
	 * @param {string} GUIID - Unique GUI ID of the popup action.
	 */
	constructor({
		id, action, buttonProps, location = POPUP_ACTION_BUTTON_LOCATION.GLOBAL, tabs = ['*'], ordinal = 0,
		visible = true, disabled = false, customButtonComponent = null, customButtonComponentProps = {}, GUIID = v4()
	}) {
		// Check for missing params
		if (!isset(id) || !id) {
			console.error(`${this.constructor.name} error! Required 'id' param is missing or empty.`);
			throw new Error(`${this.constructor.name} error! Required 'id' param is missing or empty.`);
		}
		if (!isset(action) || !action) {
			console.error(`${this.constructor.name} error! Required 'action' param is missing or empty.`);
			throw new Error(`${this.constructor.name} error! Required 'action' param is missing or empty.`);
		}
		if (!customButtonComponent && (!isset(buttonProps) || !buttonProps)) {
			console.error(`${this.constructor.name} error! Required 'buttonProps' param is missing or empty.`);
			throw new Error(`${this.constructor.name} error! Required 'buttonProps' param is missing or empty.`);
		}

		this.id = id;
		this.action = action;
		this.buttonProps = buttonProps;
		this.visible = visible;
		this.disabled = disabled;
		this.ordinal = ordinal;
		this.tabs = tabs;
		this.location = location;
		this.customButtonComponent = customButtonComponent;
		this.customButtonComponentProps = customButtonComponentProps;
		this.GUIID = GUIID;
	}
}

export default PopupComponent;

// Type definitions
/**
 * @typedef {Object} PopupComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {boolean} [hideSingleTab=false] - Flag that determines if tabs section will be hidden if there is only one
 * visible tab.
 */