import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class OfficeLocationStatisticsPage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'office-location-statistics-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
		}, 'page_title');
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
	
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>

			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, getPageActions())(OfficeLocationStatisticsPage));