import {PatientSelectOptionDataObject} from 'Components/input/PatientSelectInput';
import {getString} from 'Core/helpers/data';
import {cloneDeep, get} from 'lodash';

/**
 * Convert a patient record data object into a patient select option data object
 * 
 * @param {PatientRecordDataObject} patientRecord
 * @return {PatientSelectOptionDataObject}
 */
export const mapPatientRecordToSelectOption = patientRecord => new PatientSelectOptionDataObject(
	getString(patientRecord, 'id'),
	getString(patientRecord, 'firstName'),
	getString(patientRecord, 'middleName'),
	getString(patientRecord, 'lastName'),
	getString(patientRecord, 'address'),
	getString(patientRecord, 'telephone'),
	getString(patientRecord, 'mobilePhone'),
	getString(patientRecord, 'email'),
	cloneDeep(get(patientRecord, 'birthDate')),
	getString(patientRecord, 'recommendationId'),
	getString(patientRecord, 'recommendationName')
);