import patientRecordStyles from '../../index.module.css';
import styles from './index.module.css';

import React from 'react';
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import Label from 'Core/components/display/Label';
import PropTypes from 'prop-types';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {icon_font_delete_symbol, icon_font_edit_symbol} from 'Config/app';
import {getStringForDisplay, trimArray} from 'Core/helpers/data';
import {STANDARD_DATE_TIME_FORMAT, stringToAppDateString} from 'Core/helpers/datetime';
import PatientDialog from 'Components/dialogs/PatientDialog';
import {getGlobalActions} from 'Core/helpers/redux';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import {deletePatientAction} from 'Components/advanced/PatientRecord/actions';

class PatientDetails extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'PatientDetails',
		});

		// Initiate component's state
		this.state = {
			detailsVisible: true,
		}
		
		// GUI methods
		this.toggleShowDetails = this.toggleShowDetails.bind(this);
		
		// Action methods
		this.openEditDialog = this.openEditDialog.bind(this);
		this.openDeleteDialog = this.openDeleteDialog.bind(this);

		// Register keyboard shortcut events
		this.registerEventListener('keydown', e => {
			if (e.altKey) {
				// Prevent the default browser behaviour
				e.preventDefault();

				switch (e.key) {
					// Open the patient quick search dialog (Alt + i)
					case 'i': this.openEditDialog(); break;
					// Open the patient quick search dialog (Alt + o)
					case 'o': this.openDeleteDialog(); break;
					// no default
				}
			}
		});
	}
	
	/**
	 * Toggle showing patient details
	 * 
	 * @note This will only work for mobile screen sizes (768px or less).
	 * @return {Promise<Object>}
	 */
	toggleShowDetails() {
		const {detailsVisible} = this.state;
		return this.setState({detailsVisible: !detailsVisible});
	}
	
	/**
	 * Open the dialog to edit patient details
	 */
	openEditDialog() {
		const {data, openDialogAction, closeDialogAction} = this.props;
		const editDialogGUIID = 'edit-patient';
		const editDialogId = 'edit-patient-dialog';
		
		openDialogAction(editDialogGUIID, PatientDialog, {
			isNew: false,
			title: (
				`${this.t('title')}: ` + 
				`${data?.firstName}${data?.middleName ? ` (${data?.middleName})` : ''} ${data?.lastName}`
			),
			data,
			dialogId: editDialogId,
			onSave: patient => {
				closeDialogAction(editDialogGUIID);
				executeComponentCallback(this.props.onSave, patient);
			},
		}, {
			id: editDialogId,
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), editDialogGUIID], 'left')
		);
	}
	
	/**
	 * Open the confirm dialog to delete the patient
	 */
	openDeleteDialog() {
		const {data, openDialogAction, closeDialogAction, deletePatientAction} = this.props;

		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_patient_delete', '', '', {
				name: `${data?.firstName}${data?.middleName ? ` (${data?.middleName})` : ''} ${data?.lastName}`
			}),
			supportHtml: true,
			yesTimer: 3,
			onYes: () => {
				this.executeAbortableAction(deletePatientAction, data.id, 'confirm-patient-delete-dialog')
					// Trigger 'onDelete' event and close the confirm delete dialog if patient was successfully deleted
					.then(res => { 
						if (res) {
							executeComponentCallback(this.props.onDelete, data);
							closeDialogAction(dialogGUIID);
						}
					});
			},
		}, {
			id: 'confirm-patient-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 500
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	render() {
		const {className, data} = this.props;
		const {detailsVisible} = this.state;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${className}`}>
				<div 
					className={`section-title ${patientRecordStyles['sectionTitle']} no-select`} 
					onClick={() => this.toggleShowDetails()}
				>
					<Label 
						element="div" 
						elementProps={{className: patientRecordStyles['label']}}
						icon="list"
						content={this.t('title')}
					/>
					<div className={`tools ${patientRecordStyles['tools']}`}>
						<Button
							className="edit-patient-btn"
							tooltip={`${this.t('deleteBtn')} (${this.t('deleteBtnShortcut')})`}
							icon={icon_font_delete_symbol}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={this.openDeleteDialog}
						/>
						<Button
							className="delete-patient-btn"
							tooltip={`${this.t('editBtn')} (${this.t('editBtnShortcut')})`}
							icon={icon_font_edit_symbol}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={this.openEditDialog}
						/>
						<Button
							className="toggle-patient-details-btn"
							icon={detailsVisible ? 'minus' : 'plus'}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							onClick={() => this.toggleShowDetails()}
						/>
					</div>
				</div>
				<div 
					className={
						`section-content ${patientRecordStyles['sectionContent']} ${detailsVisible ? 'show' : 'hide'}`
					}
				>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('firstNameLabel')} />
						<Label element="span" content={getStringForDisplay(data?.firstName, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('lastNameLabel')} />
						<Label element="span" content={getStringForDisplay(data?.lastName, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('middleNameLabel')} />
						<Label element="span" content={getStringForDisplay(data?.middleName, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('birthDateLabel')} />
						<Label 
							element="span"
							content={stringToAppDateString(data?.birthDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE)}
						/>
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('telephoneLabel')} />
						<Label element="span" content={getStringForDisplay(data?.telephone, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('mobilePhoneLabel')} />
						<Label element="span" content={getStringForDisplay(data?.mobilePhone, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('addressLabel')} />
						<Label element="span" content={getStringForDisplay(data?.address, undefined, true, true)} />
					</div>
					<div className={`param ${styles['row']}`}>
						<Label element="label" content={this.t('recommendationNameLabel')} />
						<Label element="span" content={getStringForDisplay(data?.recommendationName, undefined, true, true)}/>
					</div>
				</div>
				<div className={`section-actions ${patientRecordStyles['sectionActions']}`}>
					<Button
						icon={icon_font_edit_symbol}
						label={this.t('editBtn')}
						displayStyle={BUTTON_STYLE.ACTION}
						title={this.t('editBtnShortcut')}
						onClick={this.openEditDialog}
					/>
					<Button
						icon={icon_font_delete_symbol}
						label={this.t('deleteBtn')}
						displayStyle={BUTTON_STYLE.ERROR}
						title={this.t('deleteBtnShortcut')}
						onClick={this.openDeleteDialog}
					/>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientDetails.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	// @type {PatientRecordDataObject}
	data: PropTypes.object,

	// @param {Object} rawData - Updated raw data.
	onSave: PropTypes.func,
	// @param {PatientRecordDataObject} patient - Successfully deleted patient data.
	onDelete: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PatientDetails.defaultProps = {
	id: '',
	className: '',
};

export default connect(null, getGlobalActions({deletePatientAction}))(PatientDetails);