import {cloneDeep, get} from 'lodash';
import {getNumber, getObject, getString, resetObjectValues} from 'Core/helpers/data';
import {PatientRecordDataObject} from 'Components/advanced/PatientRecord/dataObjects';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientRecordDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientRecordDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'ownerId'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'address'),
		getObject(result, 'city', null),
		getNumber(result, 'numberOfPayedAppointments', null),
		getNumber(result, 'numberOfUsedAppointments', null),
		getNumber(result, 'numberOfRemainingAppointments', null),
		getNumber(result, 'payedAmount', null),
		getString(result, 'recommendationId'),
		getString(result, 'recommendationName'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {PatientRecordDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		ownerId: result.ownerId,
		firstName: result.firstName,
		middleName: result.middleName,
		lastName: result.lastName,
		telephone: result.telephone,
		mobilePhone: result.mobilePhone,
		email: result.email,
		birthDate: getDateString(result.birthDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		address: result.address,
		city: result.city,
		numberOfUsedAppointments: getString(result, 'finishedTreatments'),
		numberOfRemainingAppointments: getString(result, 'remainingTreatments'),
		numberOfPayedAppointments: getString(result, 'payedTreatments'),
		payedAmount: getString(result, 'totalPayed'),
		recommendationName: result.recommendationName,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}