export class PatientDataObject {
	/**
	 * @param {?string} [id=null]
	 * @param {string} [ownerId='']
	 * @param {string} [firstName='']
	 * @param {string} [middleName='']
	 * @param {string} [lastName='']
	 * @param {string} [telephone='']
	 * @param {string} [mobilePhone='']
	 * @param {string} [email='']
	 * @param {?Date} [birthDate=null]
	 * @param {string} [address='']
	 * @param {?Object} [city=null]
	 * @param {string} [recommendationId='']
	 */
	constructor(
		id = null, ownerId = '', firstName = '', middleName = '', lastName = '', telephone = '', mobilePhone = '',
		email = '', birthDate = null, address = '', city = null, recommendationId = ''
	) {
		this.id = id;
		this.ownerId = ownerId;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.birthDate = birthDate;
		this.address = address;
		this.city = city;
		this.recommendationId = recommendationId;
	}
}