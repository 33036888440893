export class PatientSelectOptionDataObject {
	/**
	 * @note No default values because data object is read only.
	 * 
	 * @param {string} id
	 * @param {string} firstName
	 * @param {string} middleName
	 * @param {string} lastName
	 * @param {string} address
	 * @param {string} telephone
	 * @param {string} mobilePhone
	 * @param {string} email
	 * @param {Date} birthDate
	 * @param {string} recommendationId
	 * @param {string} recommendationName
	 */
	constructor(
		id, firstName, middleName, lastName, address, telephone, mobilePhone, email, birthDate, recommendationId, 
		recommendationName
	) {
		this.id = id;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.address = address;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.birthDate = birthDate;
		this.recommendationId = recommendationId;
		this.recommendationName = recommendationName;
	 }
}